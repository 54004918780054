* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  top: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url ("../../public/banga-1.jpg");
  background-size: cover;
  backdrop-filter: blur(10px);
  background-position: center;
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  color: white;
}

.hero-content {
  background: url("../../public/SoboEnclaveLogo.PNG") center center / cover
    no-repeat fixed ;
  width: 100%; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  position: absolute;
  top: 0px;
  color: white;
  
}

.food-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 110px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  width: 80%;
  /* height: 50vh; */ /* 100vh */
  margin: 0 auto;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: rgba(232, 221, 221, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* border-radius: 50%; */
}

.food-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 100%; */
  height: 100%;
}

.hero h1 {
  font-size: 1.5rem;
  color: black;
  text-align: center;
  z-index: 2;
  padding:  1rem;
  border-radius: 5px;
  font-family: "Dancing Script", cursive;

}

.ul-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  /* top: 110px; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
}


/***********************************************/
@media screen and (max-width: 800px) {
  .hero {
    flex-direction: column;
    /* top: 65rem; */
    height: 100%;
  }

  .ul-wrapper {
   width: 100%;
    height: 100%;
    padding: 20px;

  }

  .food-list {
    flex-direction: column;
    /* top: 65rem; */
    font-weight: 100;
    /* height: 50%; */

  }

}



