.carousel {
  background: url("../../public/banga-1.jpg") center center / cover no-repeat fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* top: 200px; */

  /* TODO Currently working on this */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.carousel-slide {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  /* padding: 10px; */
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: none;
  font-size: 24px;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-prev {
  left: 20px;
}

.carousel-next {
  right: 20px;
}

.carousel-prev:hover,
.carousel-next:hover {
  background: #666;
  color: #fff;
  border-radius: 50%;
}

.carousel-slide {
  display: none;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}

.carousel-slide.active {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

.carousel-slide.active img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border: 5px solid #fff;
  border-radius: 5px;
  transition: transform 0.9s ease-in-out; /* add a transition property */
}

.carousel-slide.active img:hover {
  transform: scale(1.1); /* add a hover effect */
}

@media screen and (max-width: 800px) {
  .carousel-slide
  {
    height: 100%;
    margin-top: 0;
    /* position: absolute; */
    

  }

  .carousel-slide.active img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border: 5px solid #fff;
    border-radius: 5px;
    transition: transform 0.9s ease-in-out; /* add a transition property */
  }

  .carousel-slide.active img:hover {
    transform: scale(1.1); /* add a hover effect */
  }

}
  
