* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  object-fit: cover;
  opacity: 0.9;
  z-index: 1;
  position: relative;
  /* background: url("../../public/banga-2.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  width: 80%;
  height: 100vh;
  margin: 140px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  z-index: 2;
  width: 20px 80%;
  margin: 0 auto;
}

/* TODO  */
.contents {
  margin-top: 50px; /* TODO control the space between the carousel*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-content h1 {
  font-size: 4rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.home-title {
  font-family: "Merriweather", serif;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-top: 100px;
}

.home-subtitle {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.order-online-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-online-btn {
  padding: 10px 20px;
  /* background: #333; */
  /* color: #fff; */
  text-decoration: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.3s ease;
  width: 200px;
  cursor: pointer;
}

.order-online-btn:hover {
  background: #333;
  color: #fff;
  /* border: 1px solid #333; */
}

h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

/* .order-online-btn a:hover {
  text-decoration: none;
  color: #fff;
} */

/**************RESPONSIVE DESIGN****************/

@media screen and (max-width: 800px) {
  .home {
    /* object-fit: cover; */
    opacity: 0.9;
    z-index: 1;
    position: relative;
    height: 100vh;
    /* margin: 230px auto; */
  }
  .banner-img {
    /* position: absolute; */
    /* top: 0px; */
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* opacity: 0.4; */
  }

  .home-content h1 {
    font-size: 3rem;
    /* color: #fff; */
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}


