* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: white;
  background-image: url("../../public/slide-15.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* background-color: #333; */
}

.menu {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
}

.menu-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: 1;
  border-radius: 1rem;
  padding: 10px;   
  /* max-width: 80%; */
  margin: 0 auto;
  backdrop-filter: blur(10px);
  text-align: center;
  margin: 5px 5px 5px 5px;
}

.menu-contents h3 {
  font-size: 1.2rem;
  color: white;
  text-transform: uppercase;
  margin-bottom: .5rem;
}

.menu-contents__image {
  width: 250px;
  height: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: 1;
  border-radius: 1rem;
  
  margin: 0 auto;
  backdrop-filter: blur(10px);
  text-align: center;
  margin: 5px;
}

.menu-contents__image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 1rem;
  
}

.menu-contents__image p {
  font-size: 1rem;
  color: white;
  text-align: justify;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  padding: 0px;
  border-radius: 5px;
  /* background-color: #333; */
  cursor: pointer;

  
  
}

.menu-contents__image p:hover {
  background-color: #333;
  color: #fff;
  cursor: pointer;
  /* padding: 2px; */
  border-radius: 5px;
   
}

.menu-contents__image button {
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  border: 1px solid white;
  padding: 10px;
  width: 100%;
  background-color: #333;
  cursor: pointer;
  border-radius: 5px;
}

.menu-contents__image button:hover {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  /* padding: 2px; */
  border-radius: 5px;
}

.menu-contents__image h6 {
  font-size: 1rem;
  color: #333;
  background-color: #fff;
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 1rem;

}

.menu-contents__image h5 {
  font-size: 1rem;
  color: #db2a2a;
  background-color: #fff;
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  width: 100%;

}



/*+++++++++++++++++++++++++++++++++++++++*/
/* Break point A */
@media screen and (max-width: 800px) {
  /* .menu {
    top: 45rem
  }
 */

  .menu-contents__image p {
    /* width: 50%; */
    padding: 20px;
  } 
}

/* Break point B */
@media screen and (max-width: 770px) {
  /* .menu-contents {
    flex-direction: column;
  } */

  .menu-contents__image {
    width: 100%;
    height: 100%;
  }

  .menu {
    top: 200px;
  }

  .menu-contents__image button {
    width: 50%;
  }

  .menu-contents__image p {
    /* width: 50%; */
    padding: 20px;
  } 

  .menu-contents__image p {
    /* width: 50%; */
    padding: 20px;
  } 
}


/* Break point F */
@media screen and (min-width: 360px) and (max-height : 640px) {


  .menu-contents__image {
    width: 100%;
    height: 100%;
  }

  .menu-contents__image button {
    width: 50%;
  }


  .menu-contents__image p {
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }

  .menu-contents__wrapper {
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    z-index: 1;
    border-radius: 1rem;
    margin: 0 auto;
    backdrop-filter: blur(10px);
    text-align: center;
    margin: 5px;

  }

  .menu-contents__wrapper h6 {
    font-size: .8rem;
    /* color: white; */
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  .menu-contents__image p {
    /* width: 50%; */
    padding: 20px;
  } 
}



