* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
}

.about-contents {
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 230px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .about-contents h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-contents h3 {
    font-size: 1.5rem;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .about-contents p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  /* Responsive and 
  Mobile Styles 
  */

  /* Galaxy Fold */

@media screen and (max-width: 280px) and (min-height: 653px) {
  .about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    margin-top: 2030px;
  }


  .about-contents {
    width: 80%;
    margin: 0 auto;
    /* padding: 40px; */
    background-color: #fff;
    border-radius: 10px;
    margin-top: 230px;
    /* margin-bottom: 20px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    text-align: center;
  }
    
    .about-contents h1 {
      font-size: 1rem;
      margin-bottom: 20px;
      
    }
    
    .about-contents h3 {
      font-size: 1.5rem;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    
    .about-contents p {
      font-size: 1rem;
      margin-bottom: 10px;
      line-height: 1.5;
      /* display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px; */

    }
  }
