* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.contact {
  /* height: 100vh; */
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../public/banga-1.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
 
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0px;
  color: white;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  position: absolute;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */

}

.contact-container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  color: white;
}

.social {
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  
}

@media screen and (max-width: 800px) {
  .contact-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-container h1 {
    font-size: 2rem;
  }
  .social {
    font-size: 1rem;
  }

  .social a {
    margin: 0 0.5rem;
  }

  .social i {
    font-size: 1.5rem;
  }

  .social p {
    font-size: 1rem;
  }


  
}