.special-container {
    padding: 20px;
    margin: 120px 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}

.card-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.card {
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* add blur */
    filter: blur(0px);
    transition: 0.5s;

}

.card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 2rem;
}   


.special-container button {
    background-color: #1d1d1d;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.special-container button:hover {
    opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .special-container {
    padding: 10px;
    margin: 200px 0;
  }

  .special-container h1 {
    font-size: 1.5rem;
  }

  .special-container p,
  .special-container li {
    font-size: 1rem;
  }

  .special-container button {
    padding: 5px 10px;
  }
}