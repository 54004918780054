* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: white;
}

.nav {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 20px; */
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(to right, #d18023 0%, #d78f46 100%);
}

.logo {
  width: 80px;
  height: 80px;
  margin: 0 20px;
  border-radius: 50%;
  background: #333;
  border: 1px solid #333;
  animation: spin 3.6s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Code for the more and less butons */
.more-btn {
  display: flex;
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
}

.more-btn:hover {
  background: #333;
  color: #fff;
}

ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}

li {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

li:hover {
  background: #333;
  border-radius: 20px;
}

a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

a:hover {
  color: #fff;
}

.links-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* gap: 20px; */
}

.link-buttons {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 140px;
  left: 12px;
  right: 12px;
  margin: 0 auto;
  transition: transform 0.5s ease-out;
  /* transform: translateX(-150%); */
  z-index: 10;
}

/* .link-buttons.show {
  transform: translateX(0);
} */

.link-buttons-transition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  transition: all 0.3s ease-in-out; /* Added transition effect */
}

.link-buttons button {
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  background: #333;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.link-buttons a {
  color: #fff;
}

.link-buttons a:hover {
  color: #333;
}

.link-buttons button:hover {
  background: #fff;
  color: #333;
}

.active {
  color: #f6d365;
  border-bottom: 2px solid #f6d365;
}

/**************RESPONSIVE DESIGN****************/
@media screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .links-divider {
    flex-direction: row;
  }

  .more-btn {
    display: block;
  }
  /* TODO 
Hide the lik-buttons div
*/
  .link-buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 190px;
    left: 12px;
    right: 12px;
    margin: 0 auto;
    transition: transform 0.3s ease-out;
    /* transform: translateX(-150%); */
  }

  /* .link-buttons.show {
    transform: translateX(0);
  } */
  .link-buttons button {
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .link-buttons {
    flex-direction: column;
    position: absolute;
    top: 190px;
    /* left: 150px;
    right: 150px; */
    margin: 0 auto;
    left: 25%;
    right: 25%;
  }
}
@media screen and (max-width: 550px) {
  .nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .links-divider {
    flex-direction: row;
  }

  .link-buttons {
    flex-direction: row;
    position: absolute;
    top: 170px;
    /* left: 150px;
    right: 150px; */
    margin: 0 auto;
  }

  .link-buttons button {
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
  }

  .links-divider {
    flex-direction: row;
  }

  .link-buttons {
    flex-direction: row;
    position: absolute;
    flex-wrap: wrap;
    top: 170px;
    /* left: 150px;
    right: 150px; */
    margin: 0 auto;
  }

  .link-buttons button {
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    /* left: 150px;
    right: 150px; */
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px;
  }

  li {
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 560px) {
  .link-buttons {
    flex-direction: row;
    position: absolute;
    flex-wrap: wrap;
    top: 230px;
    /* left: 150px;
    right: 150px; */
    margin: 0 auto;
  }
}

@media screen and (max-width: 560px) and (max-height: 480px) {
  .link-buttons {
    flex-direction: row;
    position: absolute;
    flex-wrap: wrap;
    top: 230px;
    /* left: 150px;
    right: 150px; */
    margin: 0 auto;
  }
}




